import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import Videos from "./videos";
import logo from "./logo.webp";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="App">
      <header className="App-header">
        <div>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <h4 className="app-title">
          Audio Visual <br />
          Learning Resource
        </h4>
      </header>

      <BrowserRouter>
        <Routes>
          <Route path=":edition/:series/:grade/:title/:chapter/:videoId" element={<Videos />} />
        </Routes>
      </BrowserRouter>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
