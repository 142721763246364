import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import vimeoData from "./vimeo.json";

function Videos() {
  const { videoId } = useParams();
  const [element, setElement] = useState(<h1 style={{ margin: "10vw", textAlign: "center", color: "white" }}>Please wait...</h1>);
  useEffect(() => {
    const videos = JSON.parse(JSON.stringify(vimeoData));
    const obj = videos.filter((f) => f.guid.trim() === videoId)[0];
    if (obj?.content_type === "video") {
      setElement(
        <div className="video-container">
          <iframe className="video-iframe" src={`https://player.vimeo.com/video/${obj?.uuid}?autoplay=1`} title="VK Global Video Player" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
        </div>
      );
    } else {
      window.location.href = obj?.uuid;
    }
  }, [videoId]);

  return element;
}

export default Videos;
